import stl from "../../HomePage.module.css";
import LinksToSearch from "../LinksToSearch/LinksToSearch";
import useHelpers from "@hooks/useHelpers";

export default function SearchByRangeContainer(props) {

    const { createUrl } = useHelpers();
    const {
        price_ranges,
        odometer_ranges,
    } = props.data;
    return (<>
        <div
            className={[
                "m-show",
                stl.CustomContainer,
                stl.undoCustomWidth,
            ].join(" ")}>
            <LinksToSearch
                moreLink={createUrl(
                    "/filters",
                    {
                        is_new: props.isNew,
                        sort: "price"
                    }
                )}
                range
                bgColor="#EFF6FF"
                data={price_ranges}
                fieldName={"price"}
                linkValue="value"
                fromField="from"
                toField="to"
                isNew={props.isNew}
                isPrice
            />
        </div>


        {!props.isNew && (
            <div
                className={[
                    "m-show",
                    stl.CustomContainer,
                    stl.undoCustomWidth,
                ].join(" ")}>
                <LinksToSearch
                    moreLink={createUrl(
                        "/filters",
                        {
                            is_new: props.isNew,
                            sort: "milage",
                        }
                    )}
                    bgColor="#FCEDE3"
                    data={odometer_ranges }
                    fieldName={"range"}
                    linkValue={"id"}
                    isNew={props.isNew}
                    range={true}
                />
            </div>
        )}


    </>);

}