import {Link} from "react-router-dom";
import {CDN_LINK} from "@globalConfig";
import stl from "./LinksToSearch.module.css";
import {useTranslation} from "@i18n"
import ProgressiveImage from "@componentsShared/ProgressiveImage";
import useHelpers from "@hooks/useHelpers";

const createLinkRaw = (props, _createUrl) => (item) => {
    if (props.isPrice)
        return `${_createUrl(
            "/filters",
            {
                ["condition_id"]: props.isNew ? 1 : 0,
                ["sellingprice_from"]: item.from,
                ["sellingprice_to"]: item.to,
            },
            {
                referral_location: "Home",
                browse_variant: props.isPrice ? "price" : "mileage",
                variant_value: `from ${item.from} to ${item.to}`,
            }
        )}`;


    return `${_createUrl(
        "/filters",
        {
            condition_id: props.isNew ? 1 : 0,
            odometer_from: item?.from?.replace(",", ""),
            odometer_to: item.to?.replace(",", ""),
        },
        {
            referral_location: "Home",
            browse_variant: props.isPrice ? "price" : "mileage",
            variant_value: `from ${item.from} to ${item.to}`,
        }
    )}`;

};

function LinksToSearch(props) {
    const {t} = useTranslation(["web_home"]);
    const {createUrl} = useHelpers();
    const createLink = createLinkRaw(props, createUrl);

    return (
        <>
            <div className={stl.container}>
                {props?.data?.map((item, idx) => {
                    return (
                        <Link
                            style={{backgroundColor: props.bgColor}}
                            key={"link" + idx}
                            to={createLink(item)}>
                            {`${item.text ? item.text : ""} ${item[props.fieldName]} ${
                                item.unit
                            }`}
                        </Link>
                    );
                })}
                <Link
                    to={props.moreLink}
                    className={stl.moreLink}
                >
          <span>
            {t("إعرض الكل", {
                ns: "web_home",
            })}
          </span>
                    <ProgressiveImage
                        waitUntilInteractive
                        src={CDN_LINK + "/assets/images/arrMore.png"}
                        fetchpriority="low"
                        alt=""
                        width={15}
                        height={19}
                    />
                </Link>
            </div>
        </>
    );
}

export default LinksToSearch;
